/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import "twin.macro";

export const ProgressionCard = ({
  title,
  description,
  illustration,
  to,
  total,
  completed,
  ...props
}) => {
  const Component = to ? Link : "div";
  return (
    <Component
      css="min-height:8rem"
      tw="relative rounded-2xl shadow-md transform transition hocus:(shadow-2xl scale-105) px-4 py-4 overflow-hidden"
      to={to}
      {...props}
    >
      <img tw="absolute inset-0 h-full w-full object-cover" src={illustration} alt="" />

      <div tw="relative">
        <h3 tw="text-lg leading-6 font-medium text-gray-800">{title}</h3>
        <p tw="mt-1 text-sm text-gray-500">{description}</p>
      </div>

      <ol tw="flex mt-4 space-x-2">
        {Array.from({ length: total }, (_, index) => (
          <li key={index}>
            {index < completed && (
              <span tw="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                <svg
                  tw="h-full w-full text-third-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
            {index === completed && (
              <span
                tw="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                aria-hidden="true"
              >
                <span tw="absolute h-4 w-4 rounded-full bg-secondary-400 animate-pulse"></span>
                <span tw="relative block w-2 h-2 bg-secondary-100 rounded-full"></span>
              </span>
            )}
            {index > completed && (
              <div
                tw="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                aria-hidden="true"
              >
                <div tw="h-2 w-2 bg-secondary-300 rounded-full"></div>
              </div>
            )}
          </li>
        ))}
      </ol>
    </Component>
  );
};
