// @ts-check
import { CometChat } from "@cometchat-pro/chat";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import UAParser from "ua-parser-js";
import { useLanguage } from "../common/useLanguage";
import { useLocalStorage } from "../common/useLocalStorage";
import { useFetchProfile, useUpdateProfileInformationMutation, useCandidateSignupStep } from "../profile/ProfileAPI";
import { useCandidateAPI } from "../query/ky";
import { doLogin, doLogout, useAuthenticationDispatch } from "./AuthenticationContext";

const useUserAgent = () => {
  const [parser] = useState(() => new UAParser());
  return useMemo(() => parser.getResult(), [parser]);
};

/** @type {() => (string)} */
const useDeviceType = () => {
  const ua = useUserAgent();
  return ua.device.type;
};

/** @typedef {"MOBILE" | "LAPTOP"} CandidateAuthentication */
/** @type {() => CandidateAuthentication} */
export const useCandidateAuthentication = () => {
  const deviceType = useDeviceType();
  switch (deviceType) {
    case "tablet":
    case "mobile":
      return "MOBILE";
    default:
      return "LAPTOP";
  }
};

/** @typedef {{ token: string }} AuthenticationResult */

/** @type {() => import("react-query").UseMutationResult<string, Error, { phoneNumber: string, optionalJobAdUUID: string, optionalUtmSource: string }>} */
export const useLoginMutation = () => {
  const candidateAPI = useCandidateAPI();
  const language = useLanguage();
  const candidateAuthentication = useCandidateAuthentication();
  const domainUniqueName = process.env.REACT_APP_APPLICATION_DOMAIN !== "jobfirst" ? process.env.REACT_APP_APPLICATION_DOMAIN : null;
  // If candidateAuthentication is MOBILE, find "from-job-ad in localstorage and send it back by sms to keep context"
  return useMutation({
    mutationFn: async ({ phoneNumber, optionalJobAdUUID, optionalUtmSource }) => {
      const { isValidPhoneNumber, parsePhoneNumber } = await import("react-phone-number-input");
      if (!isValidPhoneNumber(phoneNumber)) {
        throw new Error("login.InvalidPhoneNumber");
      }
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      if (parsedPhoneNumber === undefined) {
        throw new Error("login.InvalidPhoneNumber");
      }
      if (parsedPhoneNumber.country === undefined) {
        throw new Error("login.InvalidCountryCodeNumber");
      }

      return candidateAPI
        .post("public/candidate/registerorlogin", {
          json: {
            language,
            candidateAuthentication,
            optionalJobAdUUID,
            optionalUtmSource,
            phone: {
              countryIsoCode: parsedPhoneNumber.country,
              number: parsedPhoneNumber.number,
            },
            domainUniqueName: domainUniqueName
          },
        })
        .json();
    },
  });
};

/** @type {(profile: Partial<import("../profile/ProfileAPI").Profile>) => boolean} */
const isFirstLogin = (profile) => {
  return profile.phone === null || profile.email === null;
};

/** @type {() => (result: AuthenticationResult) => Promise<void>} */
const useAuthenticationSuccessHandler = () => {
  const history = useHistory();
  const dispatch = useAuthenticationDispatch();
  const [redirectTo, setRedirectTo] = useLocalStorage("redirect-to");
  const [fromJobAd] = useLocalStorage("from-job-ad");
  const fetchProfile = useFetchProfile();
  // const fetchCandidateSignupStep = useCandidateSignupStep({ jobAdUuid: fromJobAd });

  return async (result) => {
    storeAuthentication(result);
    const profile = await fetchProfile();
    // const candidateSignupStep = await fetchCandidateSignupStep()
    dispatch(doLogin(result));

    // If this is the first login, redirect to first login flow
    if (isFirstLogin(profile)) {
      if (process.env.REACT_APP_RESOURCE_SUFFIX === "phd") {
        return history.replace("/signup/success");
      } else {
        return history.replace("/signup/profile?login=first")
      }
    }

    // If we previously stored a redirection path, redirect user to it
    if (redirectTo) {
      setRedirectTo(null);
      return history.replace(redirectTo);
    }

    // Otherwise redirect to root
    return history.replace("/");
  };
};

/** @type {() => import("react-query").UseMutationResult<AuthenticationResult, Error, { sign: string }>} */
export const useLoginWithTokenMutation = () => {
  const authenticationSuccessHandler = useAuthenticationSuccessHandler();
  const candidateAPI = useCandidateAPI();
  return useMutation({
    mutationFn: async (searchParams) =>
      candidateAPI.get("public/candidate/login2", { searchParams }).json(),
    onSuccess: async (result) => {
      await authenticationSuccessHandler(result);
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<AuthenticationResult, Error, { token: string }>} */
export const useConfirmMutation = () => {
  const candidateAPI = useCandidateAPI();
  const authenticationSuccessHandler = useAuthenticationSuccessHandler();
  return useMutation({
    mutationFn: async (json) => candidateAPI.post("public/candidate/confirm", { json }).json(),
    onSuccess: async (result) => {
      await authenticationSuccessHandler(result);
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const dispatch = useAuthenticationDispatch();
  const history = useHistory();

  const logout = useCallback(async () => {
    try {
      await CometChat.logout();
    } catch (e) { }
    queryClient.clear();
    dispatch(doLogout());
    history.replace("/");
    localStorage.clear();
  }, [dispatch, history, queryClient]);

  return logout;
};

const ACCESS_TOKEN_KEY = "access_token";
/** @type {(result: AuthenticationResult) => void} */
export const storeAuthentication = (result) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, result.token);
};

/** @type {() => AuthenticationResult | null} */
export const getStoredAuthentication = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return {
    token,
  };
};

/** @type {() => void} */
export const clearStoredAuthentication = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

/** @typedef {{ accessToken: string }} FacebookTokenDetail */

/**
 * @typedef FacebookPicture
 * @property {{ url: string }} data
 */

/**
 * @typedef FacebookProfile
 * @property {string} first_name
 * @property {string} last_name
 * @property {FacebookPicture} picture
 */

/**
 * @typedef FacebookAuthenticationResult
 * @property {FacebookTokenDetail} tokenDetail
 * @property {FacebookProfile} profile
 */

/** @type {() => import("react-query").UseMutationResult<>} */
export const useAuthenticateWithFacebookMutation = () => {
  const candidateAPI = useCandidateAPI();
  const authenticationSuccessHandler = useAuthenticationSuccessHandler();
  const fetchProfile = useFetchProfile();
  const { mutateAsync: updateProfileInformation } = useUpdateProfileInformationMutation();
  return useMutation({
    mutationFn: async (params) =>
      candidateAPI
        .post("public/candidate/fb/connect", {
          body: params.tokenDetail.accessToken,
        })
        .json(),
    onSuccess: async (result, params) => {
      await authenticationSuccessHandler(result);

      // Update profile with facebook profile information
      const profile = await fetchProfile();
      if (isFirstLogin(profile)) {
        const facebookProfile = params.profile;
        await updateProfileInformation({
          firstname: facebookProfile.first_name,
          lastname: facebookProfile.last_name,
        });
      }
    },
  });
};

/**
 * @typedef Add candidate in organization (company or school) main pool and an other pool
 */
//Link a candidate to an organization (company or school) main pool and an other pool. The body is the companyUniqueUrlName and the poolUuid
export const useCandidateLinkedToMainPoolOrganizationAndPoolUuidMutation = () => {
  const api = useCandidateAPI();
  return useMutation({
    mutationFn: async (params) => {
      api.put("api/candidate/me/mainpoolandpooluuid", {
        json: params
      });
    },
    onSuccess: () => {
      localStorage.removeItem("spontaneousApplicationCompany");
      localStorage.removeItem("spontaneousApplicationPool");
      localStorage.removeItem("spontaneousApplicationJob");
      localStorage.removeItem("schoolApplicationName");
      localStorage.removeItem("schoolApplicationPool");
      localStorage.removeItem("schoolApplicationJob");
      localStorage.removeItem("EnrollStudentPoolUuid");
      localStorage.removeItem("EnrollStudentSchoolName");
      localStorage.removeItem("redirect-to")
    },
    onError: () => {
      localStorage.removeItem("spontaneousApplicationCompany");
      localStorage.removeItem("spontaneousApplicationPool");
      localStorage.removeItem("spontaneousApplicationJob");
      localStorage.removeItem("schoolApplicationName");
      localStorage.removeItem("schoolApplicationPool");
      localStorage.removeItem("schoolApplicationJob");
      localStorage.removeItem("EnrollStudentPoolUuid");
      localStorage.removeItem("EnrollStudentSchoolName");
      localStorage.removeItem("redirect-to")
    }
  });
};
