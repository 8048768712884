// @ts-check
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCandidateAPI, useExternalCandidateAPI } from "../query/ky";

export const isContextualJobAd = (jobAd) => {
  return jobAd.globalMatchingScore === undefined;
};

/** @type {() => (jobAdId: string) => import("react-query").UseQueryOptions<import("@jobook-io/jobook-job-card").JobAd | null, Error>} */
const useCreatePublicJobAdByIdQueryOptions = () => {
  const candidateAPI = useCandidateAPI();
  return (jobAdId) => ({
    queryKey: `public/jobad/${jobAdId}`,
    queryFn: async () => {
      try {
        return await candidateAPI.get(`public/jobad/${jobAdId}`).json();
      } catch (error) {
        return null;
      }
    },
    enabled: !!jobAdId,
  });
};

/** @type {(jobAdId: string) => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd | null, Error>} */
export const usePublicJobAdByIdQuery = (jobAdId) => {
  const createPublicJobAdByIdQueryOptions = useCreatePublicJobAdByIdQueryOptions();
  return useQuery(createPublicJobAdByIdQueryOptions(jobAdId));
};

export const usePublicJobAdByIdSignupStepQuery = (jobAdId) => {
  const candidateAPI = useCandidateAPI();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN
  return useQuery({
    queryKey: `public/jobad/${jobAdId}/signupstep`,
    queryFn: async () => {
      return await candidateAPI.get(`public/jobad/${jobAdId}/signupstep`).json();
    },
  });
};

/** @type {() => (jobAdId: string) => Promise<import("@jobook-io/jobook-job-card").JobAd | null>} */
const useFetchPublicJobAdById = () => {
  const createPublicJobAdByIdQueryOptions = useCreatePublicJobAdByIdQueryOptions();
  const queryClient = useQueryClient();
  return (jobAdId) => queryClient.fetchQuery(createPublicJobAdByIdQueryOptions(jobAdId));
};

/** @type {({jobAdId: string}) => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd, Error>} */
export const useMatchedJobAdQuery = ({ jobAdId }) => {
  const candidateAPI = useCandidateAPI();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN
  return useQuery({
    queryKey: `api/jobad/domain/${domain}/mymatchedjobad/${jobAdId}`,
    queryFn: async () => {
      return await candidateAPI.get(`api/jobad/domain/${domain}/mymatchedjobad/${jobAdId}`).json();
    },
  });
};

/** @type {() => (fromJobAd?: string, sourceFromJobAd?: string) => Promise<import("@jobook-io/jobook-job-card").JobAd[]>} */
const useFetchContextualJobAds = () => {
  const fetchPublicJobAdById = useFetchPublicJobAdById();
  return async (fromJobAd, sourceFromJobAd) => {
    if (!fromJobAd) {
      return [];
    }
    const jobAd = await fetchPublicJobAdById(fromJobAd);
    return jobAd ? [{ acquisitionSource: sourceFromJobAd ? sourceFromJobAd : null, source: 'jobfirst', ...jobAd }] : [];
  };
};

/** @type {() => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd[], Error>} */
export const useMatchedJobAdsQuery = () => {
  const fetchContextualJobAds = useFetchContextualJobAds();
  const candidateAPI = useCandidateAPI();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN
  return useQuery({
    queryKey: `api/jobad/domain/${domain}/mymatchedjobads`,
    queryFn: async () => {
      const fromJobAd = localStorage.getItem("from-job-ad") ? JSON.parse(localStorage.getItem("from-job-ad")) : null;
      const sourceFromJobAd = localStorage.getItem("source") ? JSON.parse(localStorage.getItem("source")) : null;
      const matchedJobAds = (await candidateAPI.get(`api/jobad/domain/${domain}/mymatchedjobads`).json()).content;
      const contextualJobAds = await fetchContextualJobAds(fromJobAd, sourceFromJobAd);
      const sourceMatchedJobAds = matchedJobAds.map(jobAd => ({ source: 'jobfirst', ...jobAd }));
      return [...contextualJobAds, ...sourceMatchedJobAds];
    },
  });
};

/** @type {({jobCode: string, latitude: number, longitude: number, distance: number}) => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd[], Error>} */
// export const usePoleEmploiAdsQuery = () => {
export const usePoleEmploiAdsQuery = ({ jobCode, latitude, longitude, distance }) => {
  const externalCandidateAPI = useExternalCandidateAPI();
  return useQuery({
    queryKey: [`api/pe/ads/candidate/matched`],
    queryFn: async () => {
      if (!jobCode || !latitude || !longitude || !distance || !process.env.REACT_APP_EXTERNAL_ADS) return [];
      try {
        const matchedJobAds = (await externalCandidateAPI.get(`api/pe/ads/${jobCode}/candidate/matched?latitude=${latitude}&longitude=${longitude}&distanceInKm=${distance}`).json()).content;
        // Workaround to delete matching
        let poleEmploiAds = []
        for (let ad of matchedJobAds) {
          delete ad.globalMatchingScore
          poleEmploiAds.push(ad);
        }
        const sourceMatchedJobAds = poleEmploiAds.map(jobAd => ({ source: 'poleemploi', ...jobAd }));
        return sourceMatchedJobAds;
      } catch (error) {
        return []
      }
    },
    enabled: !!process.env.REACT_APP_EXTERNAL_ADS
  });
};

/** @type {({jobCode: string, latitude: number, longitude: number, distance: number}) => import("react-query").UseQueryResult<Number, Error>} */
// export const usePoleEmploiAdsQuery = () => {
export const useCountPoleEmploiMatchQuery = ({ jobCode, latitude, longitude, distance }) => {
  const externalCandidateAPI = useExternalCandidateAPI();
  return useQuery({
    queryKey: [`api/pe/ads/counter`],
    queryFn: async () => {
      if (!jobCode || !latitude || !longitude || !distance || !process.env.REACT_APP_EXTERNAL_ADS) return 0;
      try {
        const counter = (await externalCandidateAPI.get(`api/pe/ads/${jobCode}/candidate/matched/count?latitude=${latitude}&longitude=${longitude}&distanceInKm=${distance}`).json());
        return counter;
      } catch (error) {
        return 0
      }
    },
    enabled: !!process.env.REACT_APP_EXTERNAL_ADS
  });
};

/** @type {() => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd[], Error>} */
export const useAppliedJobAdsQuery = () => {
  const candidateAPI = useCandidateAPI();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN;
  return useQuery({
    queryKey: `api/jobad/domain/${domain}/myappliedjobads`,
    queryFn: async () => {
      const appliedJobAds = (await candidateAPI.get(`api/jobad/domain/${domain}/myappliedjobads`).json()).content;
      return appliedJobAds;
    }
  });
};

/** @type {() => import("react-query").UseQueryResult<import("@jobook-io/jobook-job-card").JobAd[], Error>} */
export const useAppliedPoleEmploiAdsQuery = () => {
  const externalCandidateAPI = useExternalCandidateAPI();
  return useQuery({
    queryKey: [`api/pe/ads/myappliedpoleemploiads`],
    queryFn: async () => {
      if (!process.env.REACT_APP_EXTERNAL_ADS) return [];
      try {
        const appliedJobAds = (await externalCandidateAPI.get(`api/pe/ads/myappliedpoleemploiads`).json()).content;
        return appliedJobAds;
      } catch (error) {
        return []
      }
    },
    enabled: !!process.env.REACT_APP_EXTERNAL_ADS,
  });
};

/** @type {({}) => import("react-query").UseMutationResult<void, Error, string>} */
export const useApplyToJobAdMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN;
  return useMutation({
    mutationFn: async ({ jobAdUuid, acquisitionSource }) => {
      if (acquisitionSource) {
        await candidateAPI.post(`api/jobad/${jobAdUuid}/apply?acquisitionSource=${acquisitionSource}`);
      } else {
        await candidateAPI.post(`api/jobad/${jobAdUuid}/apply`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/jobad/domain/${domain}/myappliedjobads`);
      queryClient.invalidateQueries(`api/jobad/domain/${domain}/myappliedjobads`);
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, string>} */
export const useRejectJobAdMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN;
  return useMutation({
    mutationFn: async (jobAdUuid, acquisitionSource) => {
      if (acquisitionSource) {
        await candidateAPI.post(`api/jobad/${jobAdUuid}/reject?acquisitionSource=${acquisitionSource}`);
      } else {
        await candidateAPI.post(`api/jobad/${jobAdUuid}/reject`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/jobad/domain/${domain}/myappliedjobads`);
      queryClient.invalidateQueries(`api/jobad/domain/${domain}/myappliedjobads`);
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, string>} */
export const useApplyToPoleEmploiAdMutation = () => {
  const externalCandidateAPI = useExternalCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (jobAdUuid) => {
      await externalCandidateAPI.post(`api/pe/ads/${jobAdUuid}/apply`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/pe/ads/myappliedpoleemploiads`);
      queryClient.invalidateQueries(`api/pe/ads/counter`)
    },
    onError: () => {
      return null;
    }
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, string>} */
export const useRejectPoleEmploiAdMutation = () => {
  const externalCandidateAPI = useExternalCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (jobAdUuid) => {
      await externalCandidateAPI.post(`api/pe/ads/${jobAdUuid}/reject`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/pe/ads/myappliedpoleemploiads`);
      queryClient.invalidateQueries(`api/pe/ads/counter`)
    },
    onError: () => {
      return null;
    }
  });
};
