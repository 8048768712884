import styled from "@emotion/styled";
import tw from "twin.macro";

export const ButtonBase = styled("button")(
  tw`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-full`,
  tw`svg:(-ml-1 mr-3 h-5 w-5)`,
  ({ disabled }) => disabled && tw`pointer-events-none opacity-50`
);

export const ButtonBaseRoundedMd = styled("button")(
  tw`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md`,
  tw`svg:(-ml-1 mr-3 h-5 w-5)`,
  ({ disabled }) => disabled && tw`pointer-events-none opacity-50`
);

export const SecondaryButton = styled(ButtonBaseRoundedMd)(
  tw`shadow-md border-0 text-white bg-gradient-to-r from-secondary-600 bg-cover to-secondary-400 hover:(from-secondary-600 to-accent-500) focus:(outline-none ring ring-secondary-200)`
);

export const SecondaryRoundButton = styled(ButtonBase)(
  tw`shadow-md border-0 text-white bg-gradient-to-r from-secondary-600 bg-cover to-secondary-400 hover:(from-secondary-600 to-secondary-500) focus:(outline-none ring ring-secondary-200)`
);


export const PrimaryButton = styled(ButtonBase)(
  tw`shadow-md border-0 text-white bg-gradient-to-r from-primary-600 bg-cover to-primary-400 hover:(from-primary-600 to-accent-500) focus:(outline-none ring ring-primary-200)`
);


export const ThirdButton = styled(ButtonBase)(
  tw`shadow-md border-0 text-white bg-gradient-to-r from-third-600 bg-cover to-third-400 hover:(from-third-600 to-third-500) focus:(outline-none ring ring-third-200)`
);

export const Button = styled(ButtonBase)(
  tw`shadow-md text-gray-700 bg-white hover:(bg-gray-50) focus:(outline-none ring ring-gray-200)`
);

export const ToggleButton = styled(ButtonBase)(
  tw`shadow-md focus:(outline-none ring ring-third-500) cursor-pointer`,
  ({ isToggled }) =>
    isToggled
      ? tw`text-white bg-third-600 hover:(bg-third-700)`
      : tw`border-gray-300 text-gray-700 bg-white hover:(bg-gray-50)`
);
