// @ts-check
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCandidateAPI } from "../query/ky";

/** @typedef {'EN' | 'FR'} Language */

/**
 * @typedef Choice
 * @property {string} id
 * @property {null} picUrl
 * @property {{[key in Language]: string}} texts
 * @property {string} resultCode
 */
/**
 * @typedef MultipleChoicesQuestion
 * @property {'com.jobfirst.jobfirstapi.questionnaire.entities.MultipleChoicesQuestion'} _class
 * @property {string} id
 * @property {null} uuid
 * @property {'MULTI_VALUED'} questionType
 * @property {null} picUrl
 * @property {{[key in Language]: string}} stem
 * @property {Choice[]} choices
 * @property {null} answers
 * @property {number} minimumExpectedAnswers
 * @property {number} maximumExpectedAnswers
 */
/**
 * @typedef SortChoicesQuestion
 * @property {'com.jobfirst.jobfirstapi.questionnaire.entities.SortChoicesQuestion'} _class
 * @property {string} id
 * @property {null} uuid
 * @property {'SORT'} questionType
 * @property {null} picUrl
 * @property {{[key in Language]: string}} stem
 * @property {Choice[]} choices
 * @property {null} answers
 */
/** @typedef {MultipleChoicesQuestion | SortChoicesQuestion} Question */

/**
 * @typedef QuestionnairePart
 * @property {string} id
 * @property {string} uuid
 * @property {{[key: string]: Question}} questions
 */

/** @type {(questionnaireId: string, questionnairePartId: string) => import("react-query").UseQueryResult<QuestionnairePart>} */
export const useFetchQuestionnaireQuery = (questionnaireId, questionnairePartId) => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: `api/questionnaire/${questionnaireId}/${questionnairePartId}`,
    queryFn: async () =>
      candidateAPI.get(`api/questionnaire/${questionnaireId}/${questionnairePartId}`).json(),
    enabled: !!questionnaireId && !!questionnairePartId,
  });
};

/** @type {(questionnaireId: string, questionnairePartId: string) => import("react-query").UseQueryResult<QuestionnairePart>} */
export const useFetchPublicQuestionnaireQuery = (questionnaireId, questionnairePartId) => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: `public/questionnaire/${questionnaireId}/${questionnairePartId}`,
    queryFn: async () =>
      candidateAPI.get(`public/questionnaire/${questionnaireId}/${questionnairePartId}`).json(),
    enabled: !!questionnaireId && !!questionnairePartId,
  });
};

/** @typedef {MultipleChoicesQuestion & { answers: string[] }} MultipleChoicesQuestionWithAnswers */
/** @typedef {SortChoicesQuestion & { answers: string[] }} SortChoicesQuestionWithAnswers */
/** @typedef {MultipleChoicesQuestionWithAnswers | SortChoicesQuestionWithAnswers} QuestionWithAnswers */
/** @typedef {{id: string, uuid: string, questions: {[key: string]: QuestionWithAnswers}}} QuestionnairePartAnswers */

/** @type {(questionnaireId: string, questionnairePartId: string) => import("react-query").UseMutationResult<void, Error, QuestionnairePartAnswers>} */
export const useCreateQuestionnairePartAnswersMutation = (questionnaireId, questionnairePartId) => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload) => {
      await candidateAPI.post(
        `api/questionnaire/myresults/${questionnaireId}/${questionnairePartId}`,
        { json: payload }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        `api/questionnaire/myresults/${questionnaireId}/${questionnairePartId}`
      );
    },
  });
};

/** @typedef {{[key: string]: {score: string, benchmark: string, caption: string}}} QuestionnairePartResult */
/**
 * @typedef QuestionnaireResult
 * @property {string} error
 * @property {string} version
 * @property {string} input
 * @property {QuestionnairePartResult} individual
 * @property {QuestionnairePartResult} relational
 * @property {QuestionnairePartResult} operational
 * @property {{ProfileGlobal: QuestionnairePartResult}} resources
 */
/**
 * @typedef QuestionnaireResults
 * @property {string} candidateUuid
 * @property {QuestionnairePartAnswers} part1
 * @property {QuestionnairePartAnswers} part2
 * @property {QuestionnairePartAnswers} part3
 * @property {QuestionnaireResult} result
 */
/**
 * @typedef QuestionnaireResultsDto
 * @property {QuestionnaireResults} results
 * @property {string} step
 */

/**
 * Fetch results for the given questionnaire
 * @type {(questionnaireId: string) => import('react-query').UseQueryResult<QuestionnaireResultsDto>}
 */
export const useFetchQuestionnaireResultsQuery = (questionnaireId) => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: `api/questionnaire/myresults/${questionnaireId}`,
    queryFn: async () => {
      try {
        const response = await candidateAPI
          .get(`api/questionnaire/myresults/${questionnaireId}`)
          .json();
        return response;
      } catch (error) {
        // Check if error is a "soft 500" returned by the API
        // This might happen on first connections if the user did not upload a picture yet.
        //if (error instanceof HTTPError && error.response.status === 500) {
          //const response = await error.response.json();
          //if (response.message === "business:intelli7.results.not.found") {
            return {
              results: {
                candidateUuid: null,
                part1: null,
                part2: null,
                part3: null,
                result: null,
              },
              step: null
            };
          //}
        //}
      }
    },
    enabled: !!questionnaireId,
  });
};

/**
 * @typedef {{
 * id: number;
 * name: string;
 * eval: number;
 * highlighted: boolean;
 * }} RatedActivity
 */

/** @type {() => import("react-query").UseMutationResult<void, Error, { skills: RatedActivity[], activities: RatedActivity[], accreditations: RatedActivity[] }>} */
export const useSubmitSkillsQuestionnaireAnswersMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (json) => {
      await candidateAPI.put("api/candidate/me/details/skills", { json });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};

// Results are not named the same as the part they are associated with.
// This mapping translate the questionnaire part name to result key (e.g.: "intelli7" "part1" is "individual")
const questionnairePartToResultMapping = {
  intelli7: {
    part1: "individual",
    part2: "relational",
    part3: "operational",
  },
};

const getQuestionnairePartResultKey = (questionnaireId, questionnairePart) => {
  return (
    // Try to find questionnaire part result key in the defined mappings...
    questionnairePartToResultMapping[questionnaireId]?.[questionnairePart] ||
    // ...or fallback to the questionnaire part name
    questionnairePart
  );
};

export const useTopQuestionnaireResults = (
  questionnaireResults,
  questionnaireId,
  questionnairePart,
  questionnaireStep,
  topResultCount
) => {
  // Retrieve the results for the current part
  const partResults = useMemo(() => {
    // We check that we could find the questionnaire results
    if ('RESULT' !== questionnaireStep) {
      return null;
    }
    // Retrieve the result key associated with the current part
    const partResultKey = getQuestionnairePartResultKey(questionnaireId, questionnairePart);
    /** @type {{[key: string]: { score: string, benchmark: string }}} */
    const partResultBySkill = questionnaireResults.result[partResultKey];
    // Check that there are some results for this part
    if (!partResultBySkill) {
      return null;
    }
    /** @type {{ title: string, description: string, score: number, benchmark: number | null }[]} */
    const partResults = Object.entries(partResultBySkill).map(([skill, skillResult]) => ({
      id: skill,
      // Create translations keys from skill name
      title: `${skill}Title`,
      description: `${skill}Description`,
      // Convert score and benchmark to numbers
      score: parseInt(skillResult.score, 10),
      benchmark:
        // Benchmark might be "N/A" if no benchmark is available
        skillResult.benchmark === "N/A" ? null : parseInt(skillResult.benchmark, 10),
    }));
    // Sort results by descending score
    partResults.sort((skillResultA, skillResultB) => {
      return skillResultB.score - skillResultA.score;
    });
    return partResults;
  }, [questionnaireId, questionnairePart, questionnaireResults]);

  // We only display the top N results on this screen
  const topResults = useMemo(() => {
    if (!partResults) {
      return null;
    }
    return partResults.slice(0, topResultCount);
  }, [partResults, topResultCount]);

  return topResults;
};

export const getQuestionnairePartIds = (/** @type {string} */ questionnaireId) => {
  switch (questionnaireId) {
    case "intelli7":
      return ["part1", "part2", "part3"];
    default:
      throw new Error(`Could not retrieve the parts of questionnaire ${questionnaireId}`);
  }
};

export const getQuestionnairePartIdsEnum = (/** @type {string} */ questionnaireId) => {
  switch (questionnaireId) {
    case "intelli7":
      return {
        part1: ["PART_1", "PART_2", "PART_3", "RESULT"],
        part2: ["PART_2", "PART_3", "RESULT"],
        part3: ["PART_3", "RESULT"]
      };
    default:
      throw new Error(`Could not retrieve the parts of questionnaire ${questionnaireId}`);
  }
};

export const getNextQuestionnairePart = (
  /** @type {string} */ questionnaireId,
  /** @type {string} */ questionnairePartId
) => {
  const questionnairePartIds = getQuestionnairePartIds(questionnaireId);
  const index = questionnairePartIds.indexOf(questionnairePartId);
  if (index === -1) {
    throw new Error(
      `Could not find part ${questionnairePartId} in questionnaire ${questionnaireId}`
    );
  }
  return questionnairePartIds[index + 1];
};

/** @type {(questionWithAnswers: QuestionWithAnswers) => questionWithAnswers is MultipleChoicesQuestionWithAnswers} */
export const isMultipleChoiceQuestionWithAnswers = (questionWithAnswers) => {
  return questionWithAnswers.questionType === "MULTI_VALUED";
};
